<template>
    <div class="lds-ellipsis" v-if="!is_data_ready">
        <div></div>
        <div></div>
        <div></div
        >
        <div></div>
    </div>

    <div class="cr-global-table ranking-table-cricket" v-else>
        <table>
            <thead>
            <tr>
                <th>{{ $t("Rank") }}</th>
                <th class="text-left"><span class="">{{ $t("Teams") }}</span></th>
                <th>{{ $t("Points") }}</th>
                <th>{{ $t("cric.Rating") }}</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(ranking, index) in matchRanking" :key="index" :class="(ranking.team_id == $store.getters.getMatchDetailResponse.team1_id || ranking.team_id == $store.getters.getMatchDetailResponse.team2_id) ? 'heighlight':''">
                    <td>{{ranking.no}}</td>
                    <td>{{ranking.cricTeam.name}}</td>
                    <td>{{ranking.points}}</td>
                    <td>{{ranking.rating}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "ranking",

        data() {
            return {
                matchRanking: null,
                matchTeams: null,
            }
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_ranking_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_ranking_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_ranking_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        mounted() {
            this.getRanking()
        },
        serverPrefetch() {
            return this.getRanking();
        },
        methods: {
            getRanking() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$RANKING_API)
                    .then(response => {
                        if (response.data) {
                            this.matchRanking = response.data.result.ranking;
                            this.is_data_ready = true;
                        }
                    })
            },
        },

    }
</script>
